import apiClient from '@/services/axios'

const getMedications = async (query) => {
    const res = await apiClient.get(`/api/medications?q=${query}&limit=10`, {})
    if (res) {
        return res
    } else {
        return null
    }
}

const getMedicationHistory = async (patientId, doctorId) => {
    const res = await apiClient.get(`/api/medications/history?patient_id=${patientId}&doctor_id=${doctorId}`, {})
    if (res) {
        return res
    } else {
        return null
    }
}

export {
    getMedications,
    getMedicationHistory,
}