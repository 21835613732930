<template>
  <div v-if="visible" class="modal-overlay" @click.self="close">
    <div :class="modalContentClass(size)">
      <button class="modal-close" @click="close">X</button>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    size: {
      type: String,
      default: 'full',
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    modalContentClass(size) {
      // console.log(size, 'apa ukurannya?');
      if (size === 'small') {
        return 'modal-content-small';
      } else {
        return 'modal-content-full';
      }
    },
  },
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content-full {
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  width: 80%;
  max-height: 90vh;
  overflow-y: auto;
}
.modal-content-small {
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  width: 860px;
  max-height: 90vh;
  overflow-y: auto;
}
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
</style>